import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Koros } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const AngledVariation = () => {
  const rootStyle = {
    '--koros-height': '85px',
    '--hero-height': '300px',
    '--hero-width': '100%'
  };
  return <div style={{
    ...rootStyle,
    backgroundColor: 'var(--color-silver-light)',
    height: 'var(--hero-height)',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: 'var(--hero-width)'
  }}>
      <div style={{
      backgroundColor: 'var(--color-coat-of-arms)',
      clipPath: 'polygon(0 0, var(--hero-height) 0, 0 100%, 0% 100%)',
      height: '100%'
    }} />
      <Koros style={{
      fill: 'var(--color-coat-of-arms)',
      left: 'calc(-1 * var(--koros-height))',
      position: 'absolute',
      top: 'var(--koros-height)',
      transformOrigin: 'center',
      width: 'calc(2 * var(--hero-height))'
    }} rotate="135deg" mdxType="Koros" />
    </div>;
};
export const _frontmatter = {
  "slug": "/components/koros",
  "title": "Koros",
  "navTitle": "Koros"
};
const layoutProps = {
  AngledVariation,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <div role="img" aria-label="Wave motif example">
    <Koros type="basic" mdxType="Koros" />
  </div>
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Koros width should always match the screen width.`}</li>
      <li parentName="ul">{`Koros is a very powerful visual element. Use them sparingly in one view.`}</li>
      <li parentName="ul">{`Koros should only be used as filled. Do not use empty fill koros with borders.`}</li>
      <li parentName="ul">{`Use koros as part of a background. Do not create new visual elements using koros.`}</li>
      <li parentName="ul">{`It is allowed to animate Koros shapes (not included in the HDS yet).`}</li>
      <li parentName="ul">{`A koro shape can be rotated in 45 degree increments. For more information see the `}<a parentName="li" {...{
          "href": "#angled"
        }}>{`Angled koro variant documentation`}</a>{` below.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`There are six Koro styles. An added visual interest is brought to the identity by means of the Koros. Using Koros adds high visual impact and makes the user interface recognisable as part of Helsinki city services.
You can read more about using Koros in `}<ExternalLink href="https://brand.hel.fi/en/wave-motifs/" mdxType="ExternalLink">{`Helsinki Visual Identity Guidelines - Wave motifs`}</ExternalLink>{`.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <div role="img" aria-label="Wave motif, shape examples">
    <Koros type="basic" style={{
          marginBottom: 'var(--spacing-xs'
        }} mdxType="Koros" />
    <Koros type="beat" style={{
          marginBottom: 'var(--spacing-xs'
        }} mdxType="Koros" />
    <Koros type="pulse" style={{
          marginBottom: 'var(--spacing-xs'
        }} mdxType="Koros" />
    <Koros type="wave" style={{
          marginBottom: 'var(--spacing-xs'
        }} mdxType="Koros" />
    <Koros type="storm" style={{
          marginBottom: 'var(--spacing-xs'
        }} mdxType="Koros" />
    <Koros type="calm" mdxType="Koros" />
  </div>
    </PlaygroundPreview>
    <h4 {...{
      "id": "dense",
      "style": {
        "position": "relative"
      }
    }}>{`Dense`}<a parentName="h4" {...{
        "href": "#dense",
        "aria-label": "dense permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The dense Koro variant introduces a smaller wave size with the same shapes. You can choose either the Default or the Dense variant for your project. Read more about Koro usage on the `}<InternalLink href="/foundation/visual-assets/wave-motifs" mdxType="InternalLink">{`HDS Foundation - Wave motifs page`}</InternalLink>{`.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <div role="img" aria-label="Wave motif, dense variant shape examples">
    <Koros dense type="basic" style={{
          marginBottom: 'var(--spacing-xs'
        }} mdxType="Koros" />
    <Koros dense type="beat" style={{
          marginBottom: 'var(--spacing-xs'
        }} mdxType="Koros" />
    <Koros dense type="pulse" style={{
          marginBottom: 'var(--spacing-xs'
        }} mdxType="Koros" />
    <Koros dense type="wave" style={{
          marginBottom: 'var(--spacing-xs'
        }} mdxType="Koros" />
    <Koros dense type="storm" style={{
          marginBottom: 'var(--spacing-xs'
        }} mdxType="Koros" />
    <Koros dense type="calm" mdxType="Koros" />
  </div>
    </PlaygroundPreview>
    <h4 {...{
      "id": "angled",
      "style": {
        "position": "relative"
      }
    }}>{`Angled`}<a parentName="h4" {...{
        "href": "#angled",
        "aria-label": "angled permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Koro shapes can be angled in 45 degree increments. The allowed angle properties are `}<inlineCode parentName="p">{`45deg`}</inlineCode>{`, `}<inlineCode parentName="p">{`90deg`}</inlineCode>{`, `}<inlineCode parentName="p">{`135deg`}</inlineCode>{`, `}<inlineCode parentName="p">{`180deg`}</inlineCode>{`, `}<inlineCode parentName="p">{`225deg`}</inlineCode>{`, `}<inlineCode parentName="p">{`270deg`}</inlineCode>{` and `}<inlineCode parentName="p">{`315deg`}</inlineCode>{`. Angles outside of these options should be avoided.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <div role="img" aria-label="Wave motif, angled variation example">
    <AngledVariation mdxType="AngledVariation" />
  </div>
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      